import { useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import Image from '../Image/Image'
import education from '../../content/education'
import './EducationTestimonials.scss'

const { quotes } = education.testimonials_section

export default function EducationTestimonials() {
  const slickSettings: Settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  const sliderRef = useRef<Slider>(null)

  return (
    <section className="EducationTestimonials">
      <div className="EducationTestimonials__container">
        <Slider
          {...slickSettings}
          ref={sliderRef}
          className="EducationTestimonials__slider"
        >
          {quotes.map((quote) => (
            <Testimonial key={quote.top_quote} quote={quote} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

const Testimonial = ({
  quote,
}: {
  quote: {
    top_quote?: string
    main_quote: string
    author: {
      name: string
      affiliation: string
      image: string
    }
  }
}) => {
  return (
    <div key={quote.main_quote} className="EducationTestimonials__card">
      <div className="EducationTestimonials__card-content">
        {quote.top_quote && <h2>{quote.top_quote}</h2>}
        <p className="EducationTestimonials__quote">{quote.main_quote}</p>
        <div className="EducationTestimonials__author">
          <div className="EducationTestimonials__image">
            <Image src={quote.author.image} alt={quote.author.name} />
          </div>
          <div className="EducationTestimonials__author-info">
            <h3>{quote.author.name}</h3>
            <p>{quote.author.affiliation}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
