import { HeroLayout } from 'component-library'
import content from '../../content/education'
import CTALink from '../CTALink/CTALink'
import SpotlightSection from '../SpotlightSection/SpotlightSection'
import LinkComponent from '../LinkComponent/LinkComponent'
import './EducationHero.scss'

/* Content */
const { title, description, image, ctas, spotlight } = content.heroSection

export default function EducationHero() {
  return (
    <div className="EducationHero">
      {/* Hero Main */}
      <section>
        <HeroLayout
          title={title}
          description={description}
          imageUrl={image}
          bottomContent={
            <div className="EducationHero__bottom-content">
              <div className="EducationHero__cta-primary">
                <LinkComponent
                  className="Button__primary--blue"
                  href={ctas.primary.link}
                >
                  <span>{ctas.primary.text}</span>
                </LinkComponent>
              </div>

              <div className="EducationHero__cta-secondary">
                <CTALink
                  label={ctas.secondary.text}
                  href={ctas.secondary.link}
                />
              </div>
            </div>
          }
        />
      </section>

      {/* Spotlight */}
      {spotlight.announcements.length > 0 && (
        <SpotlightSection {...spotlight} />
      )}
    </div>
  )
}
