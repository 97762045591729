import { useState } from 'react'
import { Input, Select } from 'component-library'
import useForm from '../../hooks/useForm'
import content from '../../content/education'
import MarkdownText from '../MarkdownText/MarkdownText'
import './EducationContact.scss'

/* Form State */
type FormValues = {
  firstName: string
  lastName: string
  institution: string
  email: string
  role: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  institution: '',
  email: '',
  role: '',
}

interface IOption {
  label: string
  value: string
}
const GOOGLE_SHEET_URL = `https://script.google.com/macros/s/AKfycbxodXrEHMjbPXprzXjfZhX4CzgCmrPzfB9VbBQ1NxAZU4alB6Hm7N7SX-4x5P_91C44/exec`
const FORM_NAME = 'education-contact'

const { getInTouchSection } = content

export default function EducationContact() {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false)

  const { values, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues,
  })

  const submitEducationForm = async (values: FormValues) => {
    const formData = new FormData()
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('institution', values.institution)
    formData.append('email', values.email)
    formData.append('role', values.role)

    setShowFormSubmissionError(false)
    setSubmittingForm(true)

    try {
      const response = await fetch(GOOGLE_SHEET_URL, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        setIsSubmitted(true)
      } else {
        setShowFormSubmissionError(true)
      }
    } catch (error) {
      setShowFormSubmissionError(true)
    }

    setSubmittingForm(false)
  }

  return (
    <section
      id="get-in-touch"
      className="EducationContact"
      data-testid="education-contact"
    >
      <div className="EducationContact__container">
        <div className="EducationContact__form">
          <form
            onSubmit={handleSubmit(submitEducationForm)}
            name={FORM_NAME}
            className={isSubmitted ? 'hidden' : ''}
          >
            <h2 className="text-h2">{getInTouchSection.title}</h2>
            <h3 className="text-p">{getInTouchSection.description}</h3>
            <div className="grid grid-cols-2 gap-2 w-full py-6">
              <div className="flex flex-col md:flex-row col-span-2 gap-2">
                <Input
                  className="w-full md:w-1/2"
                  fieldLabelHidden
                  required
                  formName={FORM_NAME}
                  disabled={submittingForm}
                  fieldName="firstName"
                  placeholder="First name"
                  fieldLabel="First name"
                  updateValue={(value) => handleChange('firstName', value)}
                  value={values?.firstName || ''}
                />
                <Input
                  className="w-full md:w-1/2"
                  fieldLabelHidden
                  required
                  formName={FORM_NAME}
                  disabled={submittingForm}
                  fieldName="lastName"
                  placeholder="Last name"
                  fieldLabel="Last name"
                  updateValue={(value) => handleChange('lastName', value)}
                  value={values?.lastName || ''}
                />
              </div>

              <Input
                className="col-span-2 md:col-span-1"
                fieldLabelHidden
                type="email"
                required
                formName={FORM_NAME}
                disabled={submittingForm}
                fieldName="email"
                placeholder="Academic email"
                fieldLabel="Email"
                updateValue={(value) => handleChange('email', value)}
                value={values?.email || ''}
              />
              <Input
                className="col-span-2 md:col-span-1"
                fieldLabelHidden
                required
                formName={FORM_NAME}
                disabled={submittingForm}
                fieldName="institution"
                placeholder="Affiliated institution"
                fieldLabel="Affiliated institution"
                updateValue={(value) => handleChange('institution', value)}
                value={values?.institution || ''}
              />
              <div className="col-span-2">
                <Select
                  className="w-full text-brand-yellow-2"
                  fieldLabel="Academic role"
                  fieldLabelHidden
                  fieldName="academicRole"
                  formName={FORM_NAME}
                  disabled={submittingForm}
                  options={getInTouchSection.dropdownOptions}
                  placeholder={
                    <div>
                      <span className="text-[#6F767B] text-base">
                        Academic role
                      </span>
                    </div>
                  }
                  onChange={(newValue) => {
                    const newValueLocal = newValue as IOption
                    handleChange('role', newValueLocal.value)
                  }}
                />
              </div>
              <div className="col-span-2 flex justify-center">
                <button
                  type="submit"
                  className="Button__primary--yellow text-button min-w-[200px] w-full md:!w-auto disabled:!bg-grey-6 disabled:cursor-not-allowed disabled:text-grey-4 hover:opacity-80 focus-visible:opacity-80"
                  disabled={submittingForm || isSubmitted}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>

          {isSubmitted && <SuccessMessage />}

          <div
            data-testid="education-contact-error"
            className={`text-error-4 text-center ${
              !showFormSubmissionError ? 'hidden' : ''
            }`}
          >
            <p className="flex items-center justify-center gap-2">
              <i className="bx bxs-error-circle"></i>{' '}
              <span>{getInTouchSection.formErrorMessage}</span>
            </p>
          </div>

          <MarkdownText
            className={`EducationContact__privacy ${
              isSubmitted ? 'hidden' : ''
            }`}
            text={getInTouchSection.footnote}
          />
        </div>
        <div className="EducationContact__image">
          <img src={getInTouchSection.image} alt="" />
        </div>
      </div>
    </section>
  )
}

const SuccessMessage = () => {
  return (
    <div
      className="EducationContact__success"
      data-testid="education-contact-success"
    >
      <div className="mx-auto max-w-[457px]">
        <i className="bx bx-check-circle text-4xl text-primary-green-4 pb-4 "></i>
        <h2 className="text-h2">{getInTouchSection.formSubmittedTitle}</h2>
        <MarkdownText
          className="EducationContact__success-text"
          text={getInTouchSection.formSubmittedText}
        />
      </div>
    </div>
  )
}
